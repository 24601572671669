import React, { Fragment } from 'react';
import { Card, Tag } from 'antd';
import { StarFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import gameGenres from '../data/genres';

const colors = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple',
  '#2db7f5',
  '#87d068',
  '#108ee9',
  '#f50'
];

const gameGenresWithColors = gameGenres.map((genre, index) => {
  const color = colors[index] || 'black';

  return { genre, color };
});

function GameCardBig({ game, shortDate }) {
  const coloredTag = genreName => {
    const genre = gameGenresWithColors.find(({ genre }) => genre === genreName);

    return (
      <Tag style={{ marginRight: 0 }} color={genre ? genre.color : 'black'}>
        {genreName}
      </Tag>
    );
  };
  const gameRating = game.rating ? game.rating.toFixed(1) : 0;

  return (
    <Fragment>
      <Link to={`/game/${game.id}`}>
        <Card
          hoverable
          style={{ minWidth: 220, maxWidth: 360, height: '100%' }}
          className={'mx-auto'}
          cover={<img alt={'game icon'} src={game.iconURL} />}
        >
          <div className="flex mb-3 justify-between">
            <div className="w-3/5 mr-1">
              <p className="opacity-50 text-xs font-mono">
                {shortDate(game.releaseDate)}
              </p>
              <h1 className="font-logo text-base">{game.title}</h1>
              <p className="opacity-50 text-xs">{game.developerName}</p>
            </div>
            <div className="flex flex-col items-end ml-1 -mt-1">
              <div className="flex items-center">
                <span className="font-bold text-base">{gameRating}</span>
                <StarFilled
                  className="text-lg ml-1 align-text-bottom"
                  style={{ color: '#f1bc19' }}
                />
              </div>
              <span className="self-end">{coloredTag(game.genre)}</span>
              <span className="mt-1 font-mono text-xs">
                Status: <span className={`font-bold ${game.status === 'discontinued' ? 'text-red-500' : ''}`}>{game.status}</span>
              </span>
            </div>
          </div>
          <div
            className="mt-2 w-full"
            style={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: '3',
              WebkitBoxOrient: 'vertical'
            }}
          >
            <p>{game.description}</p>
          </div>
        </Card>
      </Link>
    </Fragment>
  );
}

export default GameCardBig;
