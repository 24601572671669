/* eslint-disable react/display-name */
import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Row, Col } from 'antd';
import GameCardBig from './GameCardBig';
import GameCardSmall from './GameCardSmall';
import moment from 'moment';

const GET_GAMES = gql`
  query Games($sortedBy: SortedBy!, $genres: [String!], $status: String) {
    games(sortedBy: $sortedBy, genres: $genres, status: $status) {
      id
      title
      rating
      ratingCount
      appleId
      developerName
      genre
      description
      releaseDate
      iconURL
      status
    }
  }
`;

const shortDate = dateString => {
  return moment(Number(dateString)).format('ll');
};

const Games = props => {
  const { data, loading, error } = useQuery(GET_GAMES, {
    variables: {
      sortedBy: { field: props.sorting },
      genres: props.genres,
      status: props.showDiscontinued ? null : 'active'
    },
    onCompleted: () => props.setGamesAmount(data.games.length)
  });

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error! {error.message}</div>;
  }

  return props.displaySmallCards ? (
    <div className="mx-4 my-5 md:mx-2 lg:mx-5">
      <Row gutter={[{ xs: 24, sm: 16, md: 16, lg: 16 }, 24]} type="flex">
        {data.games &&
          data.games.map(game => (
            <Col key={game.id} xs={24} sm={8} md={6} lg={4}>
              <GameCardSmall key={game.id} game={game} shortDate={shortDate} />
            </Col>
          ))}
      </Row>
    </div>
  ) : (
    <div className="mx-4 my-5 md:mx-2 lg:mx-5">
      <Row gutter={[{ xs: 24, sm: 16, md: 16, lg: 24 }, 24]} type="flex">
        {data.games &&
          data.games.map(game => (
            <Col key={game.id} xs={24} sm={12} md={8} lg={6}>
              <GameCardBig key={game.id} game={game} shortDate={shortDate} />
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default Games;
